/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* 기본 리셋 reset*/
*,
html,
body {
  font-size: 16px; // rem 단위 폰트 지정
  color: unset;
  font-weight: normal;
  line-height: unset;
  letter-spacing: unset;
}

/* You can add global styles to this file, and also import other style files */
/*reset*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
address,
em,
img,
ins,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
audio,
video {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}

body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
main,
footer,
header,
hgroup,
menu,
nav,
section {
  box-sizing: border-box;
  display: block;
}
ul,
li,
dl,
dd,
dt {
  box-sizing: border-box;
  list-style: none;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  box-sizing: border-box;
}
input,
select {
  vertical-align: middle;
}

img {
  border-style: none;
  display: block;
}
address {
  font-style: normal;
}
button,
input,
textarea,
select,
a {
  border: 0;
  outline: none;
  resize: none;
  box-sizing: border-box;
}

input,
select,
button {
  vertical-align: middle;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}
/** Correct the inability to style clickable types in iOS and Safari. */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/** * Remove the inner border and padding in Firefox. */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/** * Restore the focus styles unset by the previous rule. */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/** * 1. Add the correct box sizing in IE 10. * 2. Remove the padding in IE 10. */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}
/** * Correct the cursor style of increment and decrement buttons in Chrome. */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
/** * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari. */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/** * Remove the inner padding in Chrome and Safari on macOS. */

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/** * 1. Correct the inability to style clickable types in iOS and Safari.
* 2. Change font properties to `inherit` in Safari. */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

button {
  cursor: pointer;
}

/*reset */

/********** ionic reset ********/
ion-header,
ion-footer {
  --min-height: 56px;
  --background: #fff;
}

ion-toolbar {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --min-height: 56px;
  --background: #fff;
}

ion-tab-bar {
  min-height: 56px;
  --background: #fff;
  --border: 0;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0 0 0;
}

.footer-ios ion-toolbar:first-of-type {
  --border-width: 0;
}

ion-label {
  margin: 0;
}

ion-list-header {
  padding: 0;
  min-height: auto;
  align-items: center;
}

ion-card {
  margin: 0;
  border-radius: 0;
  box-shadow: none;
}

ion-card-header {
  padding: 0;
}
ion-card-subtitle {
  margin: 0;
}
ion-card-title {
  font-weight: normal;
}

ion-card-content.card-content-ios {
  padding: 0;
}

ion-segment {
  --background: #fff;
  border-radius: 0;
}

ion-segment-button {
  margin: 0;
  --border-radius: 0;
  --border-width: 0;
  --indicator-box-shadow: none;
  min-height: unset;
}

ion-item {
  --border-width: 0;
  --inner-border-width: 0;
  --min-height: unset;
  --padding-start: 0;
  --inner-padding-end: 0;
  --background-activated: none;
  --background-focused: none;
  --background-hover: none;
  --background-activated-opacity: 0;
  --background-focused-opacity: 0;
  --background-hover-opacity: 0;
  &::part(native) {
    padding: 0;
  }
}

ion-badge {
  padding: 0;
}

ion-button {
  margin: 0;
  height: auto;
  --padding-start: 0;
  --padding-end: 0;
  --background-activated: none;
  --background-focused: none;
  --background-hover: none;
}

ion-item-divider {
  --background: unset;
  min-height: auto;
  --inner-padding-end: 0;
  --padding-start: 0;
}

ion-grid {
  padding: 0;
}

ion-col {
  padding: 0;
}

ion-accordion {
  ion-item {
    --background-activated: #fff;
  }
}

ion-radio {
  margin: 0;
}

ion-checkbox {
  margin: 0;
}

ion-select {
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --placeholder-opacity: 1;
}

ion-avatar {
  --border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.sc-ion-input-ios-h,
.sc-ion-textarea-ios-h {
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --placeholder-color: 1;
}

.sc-ion-searchbar-ios-h {
  padding: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

/********** ionic reset ********/

////////그외 코드 참고사항

//######### modal css #########
//가로 세로 100%
.modal-wrapper.sc-ion-modal-ios {
  width: 100%;
  height: 100vh;
  border-radius: 0;
}

//######### alert css #########
ion-alert.sc-ion-alert-ios-h {
  --max-width: 280px;
  --backdrop-opacity: 0.65;
}

//######### @mixin css #########
//font style
@mixin font($color: red, $weight, $size, $line) {
  color: $color;
  font: {
    size: $size;
    weight: $weight;
    line-height: $line;
  }
}

//말줄임 1줄
.row-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
//말줄인 2줄
.rows-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: pre-line;
}

.list-none {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
  }
}

.show-back-button {
  width: 44px;
  height: 44px;
  // background: url(./assets/icon/favicon.png) no-repeat; //url
  background-size: 24px;
  background-position: center;
}
